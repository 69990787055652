import '../css/components/_modal.scss'

class Modal {
    constructor(contents, autoshow = true) {
        this.container = document.createElement('modal')
        document.body.appendChild(this.container)

        this.container.innerHTML = `<div class="modal-body">${contents}<div>`

        if (autoshow) setTimeout(() => this.show(), 100)

        return this
    }

    show() {
        this.container.classList.add('show')
        this.evl = document.addEventListener('click', (clk) => {
            if (clk.target == this.container) this.hide()
        })
        this.container.dispatchEvent(new Event('rendered'))
    }

    hide() {
        this.container.classList.remove('show')
        document.removeEventListener('click', this.evl)
    }

}

export default Modal