class InteractionLoader {
    constructor(scripts) {
        this.types = ['load', 'onload', 'pageview', 'mousemove', 'scroll', 'keydown', 'click', 'touchstart']
        this.scripts = scripts
        window.firstInteracted = window.firstInteracted || false
        window.loadedScripts = window.loadedScripts || []
        this.loaded = false

        this.handler = () => {
            this.handleInteraction()
        }

        if (window.firstInteracted) {
            this.scriptLoader()
        } else {
            this.types.forEach(type => {
                document.body.addEventListener(type, this.handler, {
                    once: true
                })
            })
        }
    }

    handleInteraction() {
        window.firstInteracted = true
        this.types.forEach(type => {
            document.body.removeEventListener(type, this.handler)
        })

        this.scriptLoader()
    }

    scriptLoader() {
        Object.keys(this.scripts).forEach(scriptKey => {
            if (window.loadedScripts.indexOf(scriptKey) === -1) {
                window.loadedScripts.push(scriptKey)
                let script = this.scripts[scriptKey]
                if (script?.type == 'font') {
                    var _newScript = document.createElement('link')
                    _newScript.href = script.url
                    _newScript.rel = "stylesheet"
                } else {
                    var _newScript = document.createElement('script')
                    _newScript.src = script.url
                }
                if (script.onload) _newScript.onload = script.onload
                if (script.async) _newScript.setAttribute('async', true)
                if (script.defer) _newScript.setAttribute('defer', true)
                if (script.data) script.data.forEach(scriptData => {
                    _newScript.dataset[scriptData[0]] = scriptData[1]
                })
                script?.type == 'font' ? document.head.appendChild(_newScript) : document.body.appendChild(_newScript)
            }
        })
    }
}

export { InteractionLoader }